import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// react components for routing our app without refresh
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// @material-ui/icons
// core components

import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"

import Parallax from "components/Parallax/Parallax.jsx"
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx"
import SEO from "../../components/SEO";

class Success extends React.Component {
  render() {
    const { classes, ...rest } = this.props
    return (
      <div>
        <SEO/>
        <Header
          brand="Planwell"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/turbines.jpg")}>
          <div className={classes.container}>
            <GridContainer />
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div
            className={classes.center}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <h2>Success!</h2>
          </div>
          <br />
          <GridContainer
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <GridItem sm={6}>
              <h3>
                Your request was successfully submitted! We'll get in touch soon!
              </h3>
            </GridItem>
          </GridContainer>
          <br/>
          <br/>
        </div>
        <Footer />
      </div>
    )
  }
}

export default withStyles(componentsStyle)(Success)
